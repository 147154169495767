<template>
  <div>
    <c-tab
      ref="tab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :popupParam.sync="popupParam"
          :survey.sync="survey"
          :evalItems.sync="evalItems"
          :evalItemClasses.sync="evalItemClasses"
          :riskPredictions.sync="riskPredictions"
          :contentHeight="contentHeight"
          :isOld.sync="isOld"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'brainCardiovascularDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',
        /**
         * 상황 : 사후관리(탭) > 뇌심혈관계 평가 이력 링크 타고 들어온 경우
         * 목적 : tab의 위치를 위험평가로 고정
         */
        isEvalHistory: false, 
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: 5,
      tab: 'info',
      survey: {
        heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 종합조사표 일련번호
        heaBrainCardiovascularStepCd: '',  // 진행상태
        userId: '',  // 근로자ID
        userName: '',  // 근로자명
        deptCd: '',  // 부서코드
        deptName: '',  // 부서명
        age: 0,  // 나이
        sexCd: '',  // 성별
        sexName: '',  // 성별
        birthDate: '', // 생년월일
        surveyDate: '',  // 평가일
        waistCircumference: '',  // 위험도_허리둘레
        bmi: '',  // 위험도_체질량 지수
        bloodPressure: '',  // 위험도_혈압
        hypertensionMedication: '',  // 위험도_고혈압 약 복용
        fastingBloodSugar: '',  // 위험도_공복 혈당
        diabetesMedication: '',  // 위험도_당뇨병 약 복용
        totalCholesterol: '',  // 위험도_총콜레스테롤
        egfr: '',  // 위험도_eGFR
        dipstickProteinuria: '',  // 위험도_Dipstick proteinuria 단백뇨
        smoking: '',  // 위험도_흡연상태
        physicallyActiveStatus: '',  // 위험도_신체 활동 여부
        regUserId: '',  //
        chgUserId: '',  //
        results: [], // 유해요인 평가결과 목록
        riskAssessment: {
          heaBrainCardiovascularRiskAssessmentId: '',  // 뇌심혈관계 위험평가 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          adjustedCompositeRelativeRisk: '',  // 조정 복합 상대위험도
          riskDevelopDisease: '',  // 발병위험도(10년)
          avgRiskDevelopDisease: '',  // 평균발병위험도(10년)
          riskOrthodonticDevelopment: '',  // 교정발병위험도(10년)
          reducedRiskDevelopDisease: '',  // 발병위험도 감소치
          cardiovascularAge: '',  // 심뇌혈관 나이
          correctedCardiovascularAge: '',  // 교정 심뇌혈관 나이
          integratedRiskClassificationCriteria: '',  // 통합형 위험도 분류기준
          /** 기타 정보 */
          complexRelativeRisk: '',  // 개인별 복합 상대위험도
          correctionCompositeRelativeRisk: '',  // 개인별 교정 복합 상대위험도
          regUserId: '',  //
          chgUserId: '',  //
        }, // 위험평가
        suitEval: {
          heaBrainCardiovascularWorkSuitEvalId: '',  // 뇌심혈관계 업무적합성 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          userId: '',  // 근로자ID (종합조사표 값)
          userName: '',  // 근로자명 (종합조사표 값)
          deptCd: '',  // 부서코드 (종합조사표 값)
          deptName: '',  // 부서명 (종합조사표 값)
          tasksPerformed: '',  // 수행업무
          diseaseReview: '',  // 임상증상 / 동반질환 검토의견
          habitsReview: '',  // 생활습관 / 검사항목 검토의견
          businessFactorsReview: '',  // 업무적요인 검토의견
          generalOpinionCd: '',  // 종합의견 - 결과
          generalOpinion: '',  // 종합의견 - 적합 이외인 경우 기술
          heaManagerId: '',  // 보건담당자 OR 의사
          regUserId: '',  //
          chgUserId: '',  //
        }, // 업무적합성 평가서
      },
      evalItems: [],
      evalItemClasses: [],
      riskPredictions: [],
      editable: true,
      detailUrl: '',
      evalItemListUrl: '',
      riskPredictionListUrl: '',
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.heaBrainCardiovascularSurveyId)
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'edit', label: '종합조사표', component: () => import(`${'./brainCardiovascularInfo.vue'}`), key: uid() },
      ]
      // HBCS000001	종합조사표 작성중
      // HBCS000005	위험평가 확인중
      // HBCS000010	업무적합성 평가서 작성중
      // HBCS000015	사후관리
      if (this.survey.heaBrainCardiovascularStepCd === 'HBCS000005') {
        _items = this.$_.concat(_items, [
          { name: 'riskAssessment', icon: 'build', label: '위험평가', component: () => import(`${'./brainCardiovascularRiskAssessment.vue'}`), key: uid() },
        ])
      } else if (this.survey.heaBrainCardiovascularStepCd === 'HBCS000010') {
        _items = this.$_.concat(_items, [
          { name: 'riskAssessment', icon: 'build', label: '위험평가', component: () => import(`${'./brainCardiovascularRiskAssessment.vue'}`), key: uid() },
          { name: 'suitEval', icon: 'build', label: '업무적합성 평가서', component: () => import(`${'./brainCardiovascularSuitEval.vue'}`), key: uid() },
        ])
      } else if (this.survey.heaBrainCardiovascularStepCd === 'HBCS000015') {
        _items = this.$_.concat(_items, [
          { name: 'riskAssessment', icon: 'build', label: '위험평가', component: () => import(`${'./brainCardiovascularRiskAssessment.vue'}`), key: uid() },
          { name: 'suitEval', icon: 'build', label: '업무적합성 평가서', component: () => import(`${'./brainCardiovascularSuitEval.vue'}`), key: uid() },
          { name: 'postProcessing', icon: 'build', label: '사후관리', component: () => import(`${'./brainCardiovascularPostProcessing.vue'}`), key: uid() },
        ])
      }
      return _items;
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    checkboxItems() {
      return [
        'BCWI000010',
        'BCWI000012',
        'BCWI000017',
        'BCWI000018',
        'BCWI000019',
        'BCWI000020',
        'BCWI000021',
        'BCWI000022',
      ]
    },
  },
  watch: {
  },  
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.bcs.survey.get.url
      this.evalItemListUrl = selectConfig.hea.bcs.evalItem.list.url
      this.riskPredictionListUrl = selectConfig.hea.bcs.riskPrediction.list.url
      // code setting
      this.$comm.getComboItems('WORK_SUIT_EVAL_ITEM_CLASS_CD').then(_result => {
        this.evalItemClasses = _result
      });
      // list setting
      this.getEvalItems();
      this.getRiskPredictions();
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {    
        this.$http.url = this.$format(this.detailUrl, this.popupParam.heaBrainCardiovascularSurveyId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.survey, _result.data)

          if (!this.popupParam.isEvalHistory) {
            if (this.survey.heaBrainCardiovascularStepCd === 'HBCS000001') {
              this.tab = 'info'
            } else if (this.survey.heaBrainCardiovascularStepCd === 'HBCS000005') {
              this.tab = 'riskAssessment'
            } else if (this.survey.heaBrainCardiovascularStepCd === 'HBCS000010') {
              this.tab = 'suitEval'
            } else {
              this.tab = 'postProcessing'
            }
          } else {
            this.tab = 'riskAssessment'
          }
        },);
      } else {
        // nothing...
      }
    },
    getEvalItems() {
      this.$http.url = this.evalItemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y'
      };
      this.$http.request((_result) => {
        this.evalItems = _result.data;

        if (!this.isOld) {
          this.$set(this.survey, 'results', this.$_.map(this.evalItems, evalItem => {
            return {
              heaBrainCardiovascularEvalResultId: '',  // 뇌심혈관계 평가결과 일련번호
              heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
              heaBrainCardiovascularEvalItemId: evalItem.heaBrainCardiovascularEvalItemId,  // 뇌심혈관계 평가항목 일련번호
              workSuitEvalItemClassCd: evalItem.workSuitEvalItemClassCd,  // 위험요인 구분코드
              workSuitEvalItemClassName: evalItem.workSuitEvalItemClassName,  // 위험요인 구분코드
              riskFactors: evalItem.riskFactors,  // 위험요인
              riskFactorsResult: this.$_.indexOf(this.checkboxItems, evalItem.heaBrainCardiovascularEvalItemId) === -1 ? '' : 'N',  // 위험요인 평가결과(현재상태)
              riskFactorsGoalResult: '',  // 목표상태
              healthConditionCd: '',  // 건강신호등
              regUserId: this.$store.getters.user.userId,  //
              editFlag: 'C'
            }
          }))
        }
      },);
    },
    getRiskPredictions() {
      this.$http.url = this.riskPredictionListUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.riskPredictions = _result.data;
      },);
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
